import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import image1 from './SectionAssets/category-image1.png';
import image2 from './SectionAssets/category-image2.png';
import image3 from './SectionAssets/category-image3.png';
import image4 from './SectionAssets/category-image4.png';
import image5 from './SectionAssets/category-image5.png';
import image6 from './SectionAssets/category-image6.png';
import image7 from './SectionAssets/category-image7.png';
import image8 from './SectionAssets/category-image8.png';

import css from './Sections.module.css';

const getSteps = intl => [
  {
    img: image1,
    title: intl.formatMessage({ id: 'BrowseCategory.academicUniform.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.academicUniform.description' }),
    search: 's?pub_listingType=school_uniforms&pub_subcategory=school_uniforms_academic_uni',
  },
  // {
  //   img: cadetImage,
  //   title: intl.formatMessage({ id: 'BrowseCategory.sportingUniforms.title' }),
  //   description: intl.formatMessage({ id: 'BrowseCategory.sportingUniforms.description' }),
  //   search: 's?pub_listingType=books',
  // },
  {
    img: image2,
    title: intl.formatMessage({ id: 'BrowseCategory.peUniform.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=school_uniforms&pub_subcategory=school_uniforms_sporting_uni',
  },
  {
    img: image3,
    title: intl.formatMessage({ id: 'BrowseCategory.schoolDanceAndFormal.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=rec_items&pub_subcategory=rec_items_school_formal',
  },
  {
    img: image4,
    title: intl.formatMessage({ id: 'BrowseCategory.bookWeekCostume.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=rec_items&pub_subcategory=rec_items_book_week',
  },
  {
    img: image5,
    title: intl.formatMessage({ id: 'BrowseCategory.musicInstruments.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=rec_items&pub_subcategory=rec_items_music_instruments',
  },
  {
    img: image6,
    title: intl.formatMessage({ id: 'BrowseCategory.dancewearAndGym.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=rec_items&pub_subcategory=rec_items_dance_gymnastics',
  },
  {
    img: image7,
    title: intl.formatMessage({ id: 'BrowseCategory.clubSportsWear.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=rec_items&pub_subcategory=rec_items_club_uniforms',
  },
  {
    img: image8,
    title: intl.formatMessage({ id: 'BrowseCategory.skiAndCamp.title' }),
    description: intl.formatMessage({ id: 'BrowseCategory.peUniform.description' }),
    search: 's?pub_listingType=rec_items&pub_subcategory=rec_items_ski_camp_gear',
  },

  // {
  //   img: corePeUniform,
  //   title: intl.formatMessage({ id: 'BrowseCategory.textbooks.title' }),
  //   description: intl.formatMessage({ id: 'BrowseCategory.textbooks.description' }),
  //   search: 's?pub_listingType=rec_items',
  // },
];

function BrowseCategory(props) {
  const history = useHistory();
  const { intl } = props;

  function Step({ step }) {
    return (
      <div className={css.sliderItem} onClick={() => history.push(step?.search)}>
        <div className={css.imageBlock}>
          <img src={step.img} alt={step.title} />
        </div>
        <h3>{step.title}</h3>
        {/* <p>{step.description}</p> */}
      </div>
    );
  }

  return (
    <div className={classNames(css.popularSessions, css.sectionPadding)}>
      <div className={css.contentWidth}>
        <div className={css.sectionHead}>
          <div className={css.leftSec}>
            <h2>{intl.formatMessage({ id: 'BrowseCategory.heading' })}</h2>
          </div>
        </div>
        <div className={css.templatesSlider}>
          {getSteps(intl).map((step, index) => (
            <Step key={index} step={step} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default BrowseCategory;
