import React from 'react';
import banner from '../../../assets/sustainability-banner.jpg';
import bgLogo from '../../../assets/bg-logo.jpg';
import classNames from 'classnames';

import css from './Sections.module.css';

function SectionSustainability(props) {
  return (
    <div className={css.contentWidth}>
      <div className={classNames(css.sustainabilitySection, css.sectionPadding)}>
        <div className={css.sustainabilityBanner}>
          <div className={css.sustainabilityBannerLeft}>
            <h2>Every transaction is a step forward in our journey to sustainability</h2>
          </div>
          <div className={css.sustainabilityBannerRight}>
            <img src={banner} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionSustainability;
